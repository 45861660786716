import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
import { type EmblaCarouselType } from 'embla-carousel';
import { useCallback } from 'react';

export const usePrevNextButtons = (emblaApi?: EmblaCarouselType) => {
  const onPrevClick = useCallback(
    (e) => {
      e.preventDefault();

      if (emblaApi) {
        emblaApi.scrollPrev();
      }
    },
    [emblaApi]
  );

  const onNextClick = useCallback(
    (e) => {
      e.preventDefault();

      if (emblaApi) {
        emblaApi.scrollNext();
      }
    },
    [emblaApi]
  );

  return { onPrevClick, onNextClick };
};

interface EmblaArrowButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function EmblaPrevButton({ onClick }: EmblaArrowButtonProps) {
  return (
    <button
      type="button"
      className="embla__button embla__button--prev"
      onClick={onClick}
      aria-label="Previous"
    >
      <ArrowBackIosNewIcon />
    </button>
  );
}

export function EmblaNextButton({ onClick }: EmblaArrowButtonProps) {
  return (
    <button
      type="button"
      className="embla__button embla__button--next"
      onClick={onClick}
      aria-label="Next"
    >
      <ArrowForwardIosIcon />
    </button>
  );
}
