import { SxProps } from '@mui/material';
import { lightTheme } from '../../../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  container: {
    position: 'relative',
    '.embla__button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      border: 'none',
      borderRadius: '50%',
      background: palette.grey[500],
      opacity: '0.6',
      color: palette.grey['50'],
      width: '1.75rem',
      aspectRatio: '1 / 1',
      padding: '0',
      ':hover': {
        opacity: '0.8'
      },
      '& > svg': {
        fontSize: {
          xs: '.9rem',
          md: '1.2rem'
        }
      }
    },
    '.embla__button--prev': {
      left: spacing(0.5)
    },
    '.embla__button--next': {
      right: spacing(0.5)
    }
  },
  scrollViewport: {
    overflow: 'hidden'
  },
  scrollContainer: {
    display: 'flex'
  },
  slide: {
    flex: '0 0 100%',
    minWidth: '100%',
    objectFit: 'cover',
    display: 'block'
  }
};

export default styles;
