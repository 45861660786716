import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import { isReactApp } from '../../utils/reactUtils';
import { boxShadow } from '../../theme/colorPalette';

const { palette, spacing } = lightTheme;

const isPortal = isReactApp();

const highlightedCard = {
  height: '100%',
  padding: spacing(0.5),
  display: 'block',
  position: 'relative',
  boxShadow: boxShadow[400],
  backgroundColor: palette.primary['400'],
  transitionDuration: '1s',
  transitionProperty: 'box-shadow, background-color',
  transitionTimingFunction: 'ease-in',
  animation: 'attention 1s forwards',
  '@keyframes attention': {
    '0%': { transform: 'scale(1.01)' },
    '50%': { transform: 'scale(1.03)' },
    '100%': { transform: 'scale(1)' }
  },
  '.addressText': {
    color: palette.primary.main
  },
  '.iconText': {
    color: palette.primary.main
  },
  '.svgIcon': {
    '& svg': {
      '& path': {
        fill: palette.primary.main
      }
    }
  }
};

const styles: Record<string, SxProps> = {
  root: {
    position: 'relative',
    minHeight: '24rem',
    transition: '0.5s',

    '.baseContent': {
      transition: '0.5s'
    },

    '.MuiCardActionArea-root:hover': {
      backgroundColor: 'transparent'
    },
    '.MuiCardActionArea-focusHighlight': {
      background: 'transparent'
    },

    '.hoverPoint': {
      position: 'absolute',
      zIndex: 2,
      width: '1rem',
      height: '100%',
      top: 0
    }
  },

  // This styles only apply to webApp
  webRoot: {
    position: 'relative',
    transition: '0.5s',
    width: { xs: '21rem', sm: '19.25rem' },
    height: '21.31rem',
    maxHeight: '22rem',

    '&.highlightCard': {
      ...highlightedCard,
      // This height defined applied on card when it is selected
      height: '21.31rem',
      backgroundColor: palette.primary['400']
    },

    '.baseContent': {
      transition: '0.5s'
    },

    '.MuiCardActionArea-root:hover': {
      backgroundColor: 'transparent'
    },

    '.MuiCardActionArea-focusHighlight': {
      background: 'transparent'
    },

    '.hoverPoint': {
      position: 'absolute',
      zIndex: 2,
      width: '1rem',
      height: '100%',
      top: 0
    }
  },

  tiltLeft: {
    position: 'absolute',
    zIndex: 2,
    width: '1rem',
    height: '100%',
    top: 0,
    left: 0,
    ':hover ~ .baseContent': {
      transformOrigin: 'left center',
      transform: ' perspective(100rem) rotateY(-7deg)',
      ...highlightedCard
    }
  },

  tiltRight: {
    position: 'absolute',
    zIndex: 2,
    width: '1rem',
    height: '100%',
    top: 0,
    right: 0,
    ':hover ~ .baseContent': {
      transformOrigin: 'right center',
      transform: ' perspective(100rem) rotateY(7deg)',
      ...highlightedCard
    }
  },

  // This styles only applies to portal reactApp
  baseStateOpen: {
    height: '100%',
    padding: spacing(0.5),
    backgroundColor: palette.grey['50'],
    display: 'block',
    position: 'relative',
    boxShadow: boxShadow['300'],
    borderRadius: '0.5rem',
    a: {
      height: '100%'
    },

    '&.highlightCard': {
      ...highlightedCard,
      backgroundColor: palette.primary['400']
    },

    '.svgIcon': {
      '& svg': {
        '& path': {
          fill: palette.grey[400]
        }
      }
    },
    '&:hover': {
      backgroundColor: palette.primary['400'],
      boxShadow: boxShadow[400],
      '.addressText': {
        color: palette.primary.main
      },

      '.iconText': {
        color: palette.primary.main
      },

      '.svgIcon': {
        '& svg': {
          '& path': {
            fill: palette.primary.main
          }
        }
      }
    }
  },

  // This styles only applies to webApp
  baseStateOpenWeb: {
    // This height defined applied on card in normal state
    height: '21.81rem',
    padding: spacing(0.5),
    cursor: 'pointer',
    backgroundColor: palette.grey['50'],
    display: 'block',
    position: 'relative',
    boxShadow: boxShadow['300'],
    borderRadius: '0.5rem',
    a: {
      height: '100%'
    },
    '&.highlightCard': {
      ...highlightedCard,
      // This height defined applied on card when it is selected
      height: '21.31rem',
      backgroundColor: palette.primary['400']
    },
    '.svgIcon': {
      '& svg': {
        '& path': {
          fill: palette.grey[400]
        }
      }
    },
    '&:hover': {
      backgroundColor: palette.primary['400'],
      boxShadow: boxShadow[400],
      '.addressText': {
        color: palette.primary.main
      },

      '.iconText': {
        color: palette.primary.main
      },

      '.svgIcon': {
        '& svg': {
          '& path': {
            fill: palette.primary.main
          }
        }
      }
    }
  },

  feesMayApplyCta: {
    alignSelf: 'flex-start',
    fontWeight: '500',
    fontSize: '0.875rem',
    textDecoration: 'underline',
    textTransform: 'none',
    padding: '0',
    height: '0',
    textAlign: 'left'
  },

  // This style would apply only to sneak peek properties on webApp
  baseStateSneakPeak: {
    width: { xs: '21rem', sm: '19.25rem' },
    height: '21.31rem',
    borderRadius: '0.5rem',
    padding: spacing(0.5),
    background: palette.primary.main,
    display: 'block',
    position: 'relative',
    '&:hover': {
      width: { xs: '21.44rem', sm: '19.25rem' },
      height: '21.81rem'
    },

    '&.highlightCard': {
      '.MuiBox-root': {
        background: `${palette.primary['400']} !important`
      },
      '.selectedSneakPeakTextMain': {
        color: palette.primary.main
      },
      '.selectedSneakPeakTextSecondary': {
        color: palette.grey.main
      }
    }
  },
  cardContainer: {
    '&.MuiCardContent-root': {
      padding: 0,
      marginBottom: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '48%'
    }
  },

  detailsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  addressText: {
    marginBottom: isPortal ? '0.25rem' : spacing(0.5),
    color: palette.grey.main,
    fontWeight: 600,
    wordBreak: 'break-word',
    fontSize: '1.125rem'
  },

  addressAndOtherDetails: {
    marginTop: spacing(0.5),
    height: '4.5rem'
  },

  addressContainerForMarketPage: {
    height: 'auto'
  },

  bedBathAreaDetails: {
    gap: '1.5rem',
    display: 'flex',
    flexDirection: 'row'
  },

  priceBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.51rem'
  },

  priceBlockWeb: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.5rem'
  },

  weeklyRent: {
    color: palette.primary.main,
    ...(isPortal
      ? {
          fontWeight: 700,
          fontSize: '1.5rem',
          lineHeight: '1.5rem'
        }
      : {})
  },

  iconTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      width: '1rem',
      height: 'auto'
    }
  },

  iconText: {
    color: palette.grey[400],
    marginLeft: '0.25rem',
    display: 'flex',
    alignItems: 'center'
  },

  imageContainer: {
    display: 'block',
    // overriding responsive carousel image height for list card only
    // Responsive carousel: '& .photo'
    '.alice-carousel__stage-item': {
      height: '12rem !important'
    }
  },

  imageContainerPortal: {
    display: 'block',
    // overriding responsive carousel image height for list card only
    // Responsive carousel: '& .photo'
    img: {
      height: '13rem !important'
    },
    '.alice-carousel__stage-item': {
      height: '13rem !important'
    }
  },

  carouselArrowNext: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    cursor: 'pointer',
    color: palette.grey[50],
    svg: {
      fill: palette.grey[50]
    }
  },

  featureWrapper: {
    marginTop: '0.625rem'
  },

  carouselArrowPrev: {
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    cursor: 'pointer',
    color: palette.grey[50],
    svg: {
      fill: palette.grey[50]
    }
  },

  imgCarousel: {
    objectFit: 'cover'
  },

  videoTourIconBox: {
    position: 'absolute',
    top: '1.5rem',
    left: '1.5rem',
    zIndex: 1
  },

  tagNameBox: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    zIndex: '2',
    width: '62%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: '0.25rem',
    '& > div': {
      gap: '0.5rem',
      display: 'flex',
      alignItems: 'center'
    }
  },

  overlayWrapper: {
    zIndex: 3,
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  sneakPeakTextMain: {
    color: palette.secondary.main,
    marginBottom: spacing(0.5)
  },

  sneakPeakTextSecondary: {
    color: palette.grey['50'],
    marginBottom: spacing(1)
  },

  btnLink: {
    textDecoration: 'none'
  },
  portalCardShadow: {
    boxShadow: `0rem 0.2409rem 0.9637rem 0rem rgba(151, 151, 151, 0.16)`,
    borderRadius: '0.5rem'
  },

  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
    width: '100%',
    '& > button': {
      width: '100%',
      height: '2.12rem',
      fontSize: '0.75rem',
      textTransform: 'none',
      paddingX: '0rem'
    }
  },

  iconBtn: {
    boxShadow: boxShadow.main,
    height: '1.5rem',
    width: '1.5rem'
  },

  icon: {
    color: palette.grey.main,
    height: '0.853rem',
    width: '0.848rem'
  },

  iconFilled: {
    color: palette.primary.main,
    height: '0.853rem',
    width: '0.848rem'
  },

  newHomeAddressText: {
    minHeight: '2.75rem',
    mt: '0.5rem'
  },

  priceRange: {
    fontSize: {
      xs: '0.75rem',
      md: '0.875rem'
    },
    mb: '1.5rem'
  },
  sneakPeekLineHeight: {
    mt: '0.6rem',
    mb: '1.07rem'
  },

  localLifeAddressText: {
    minHeight: '1.5rem',
    mb: '0.1rem',
    fontSize: '1.25rem',
    fontWeight: 400
  },
  localLifeArea: {
    color: palette.grey[400],
    mb: '0rem'
  },
  signInButton: {
    textTransform: 'none'
  },

  drawerBtn: {
    fontSize: '0.875rem',
    textDecoration: 'underline',
    textTransform: 'none',
    py: { xs: '0.5rem', md: '0.25rem' },
    px: { xs: '0rem', md: '0rem' },
    height: '0',
    textAlign: 'left'
  },

  localLifeCard: {
    minHeight: '5rem !important',
    paddingY: '0.01rem',
    paddingX: '0.1rem',
    minWidth: '15rem',
    width: '16rem'
  },

  localLifeCardTitle: {
    color: palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 700
  },

  closeIcon: {
    width: 18,
    height: 18,
    float: 'right'
  },

  saleInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    py: '0.6rem'
  },

  saleText: {
    fontWeight: 400,
    fontSize: '0.875rem',
    pl: '0.4rem',
    color: palette.grey['500']
  },

  maxWidthForCard: {
    maxWidth: '25rem'
  },

  marketPageViewCard: {
    position: 'relative',
    transition: '0.5s',
    width: { xs: '21.438rem', sm: '19.25rem' },
    height: '21.31rem',

    '.baseContent': {
      transition: '0.5s'
    },

    '.MuiCardActionArea-root:hover': {
      backgroundColor: 'transparent'
    },

    '.MuiCardActionArea-focusHighlight': {
      background: 'transparent'
    },

    '.hoverPoint': {
      position: 'absolute',
      zIndex: 2,
      width: '1rem',
      height: '100%',
      top: 0
    }
  },

  overflowControl: {
    overflow: 'hidden'
  },
  localExpertHeight: {
    height: '20.5rem'
  },

  wrapText: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden'
  }
};

export default styles;
