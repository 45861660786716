import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgVideoTourSolid = (props) => (
  <SvgIcon {...props} title="VideoTourSolid">
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#017163" />
      <g clipPath="url(#VideoTourSolid_svg__a)">
        <path
          d="M13.898 9.25c.09 0 .165.074.165.165v5.17a.165.165 0 0 1-.165.165H7.353a.165.165 0 0 1-.165-.165v-5.17c0-.091.073-.165.165-.165h6.545Zm.852-1.375H6.5a.69.69 0 0 0-.688.688v6.874c0 .379.31.688.688.688h8.25a.69.69 0 0 0 .688-.688v-2.406l1.341 1.342a.825.825 0 0 0 1.409-.583v-3.58a.825.825 0 0 0-1.409-.583l-1.341 1.342V8.563a.69.69 0 0 0-.688-.688Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="VideoTourSolid_svg__a">
          <path
            fill="#fff"
            transform="translate(3.75 3.75)"
            d="M0 0h16.5v16.5H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default SvgVideoTourSolid;
