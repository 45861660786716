// libs
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Link,
  IconButton,
  SxProps
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteUnfilledIcon from '@mui/icons-material/FavoriteBorder';
import CloseIcon from '@mui/icons-material/Close';
import deepmerge from '@mui/utils/deepmerge';
import {
  dynamicImport,
  getEnvVar,
  isReactApp,
  useRouter
} from '../../utils/reactUtils';
import Image from '../CImageComponent';
// local-imports
import PropertyPill from '../PropertyPills';
import { Button } from '../Button';
import FeesMayApplySection from '../../layouts/FeesMayApplySection';
// icons
import SvgBathtub from '../../icons/Bathtub';
import SvgBed from '../../icons/Bed';
import SvgHouseArea from '../../icons/HouseArea';
import VideoTourSolid from '../../icons/VideoTourSolid';
// styles&utils
import {
  getUserFromStorage,
  photoAssetUrlNew,
  convertPrice,
  getAuthActionFromSessionStorage,
  checkMortgageData
} from '../../utils/index';
import useClientOnly from '../../utils/customHooks/useClientOnly';
import {
  getUserFavoritePropertiesList,
  togglePropertyFavorite
} from '../../utils/httpRequest';
import styles from './styles';

import propertyListPagePathConstant from '../../utils/Constants/propertyListPagePathConstant';
import EmblaImageCarousel from '../EmblaCarousel/EmblaImageCarousel';

const Alert = dynamicImport(() => import('../Alert'));
const Tooltips = dynamicImport(() => import('../Tooltip'));
const LenderSelectionDrawer = dynamicImport(
  () => import('../LenderSelectionDrawer')
);

export interface TagTypes {
  availableForAuction?: boolean;
  isNewHome?: boolean;
  isBuildToRent?: boolean;
  isReduced?: boolean;
  isSneakPeek?: boolean;
  isRecentSold?: boolean;
  isRecentLet?: boolean;
  landLordAcceptZeroDeposit?: boolean;
  isRecommended?: boolean;
  hopefullyOfInterest?: boolean;
  handpickedForYou?: boolean;
  isNew?: boolean;
  isLastViewed?: boolean;
  isChecked?: boolean;
  isMatched?: boolean;
  isProperty?: boolean;
  isTopTenancy?: boolean;
  isQuickSale?: boolean;
}
export interface LibPropertyListCardProps {
  property?: any;
  isRecommendedProperty?: boolean;
  selectedPropertyIdCard: string;
  isPortal?: {
    isPortalCard?: boolean;
    linkSite?: string;
    carouselImages?: Array<{ id: string; title: string; url: string }>;
    address?: string;
    bedrooms?: string;
    bathrooms?: string;
    area?: string;
    isPropertyRent?: boolean;
    isPricePerMonth?: boolean;
    isSneakPeak?: boolean;
    isSalesPrice?: boolean;
    weeklyRent?: string;
    videoTour?: boolean;
    tags?: TagTypes;
    portalAssetBaseUrl?: string;
    searchPropertyType?: string;
    isMapView?: boolean;
  };
  cardButtons?: {
    primaryBtn?: {
      label: string;
      primaryBtnClick?: (e: React.MouseEvent) => void;
    };
    secondaryBtn?: {
      label: string;
      secondaryBtnClick?: (e: React.MouseEvent) => void;
    };
    label?: React.ReactNode;
  };
  heartIconOnClick?: (
    e: React.MouseEvent,
    currentInternalState: boolean
  ) => void;
  handleLoginClick?: (e: React.MouseEvent) => void;
  favorited?: boolean;
  isEagerImg?: boolean;
  isPropertySelected?: boolean;
  isNewHomePage?: boolean;
  isMapCard?: boolean;
  cancelSelection?: () => void;
  isNew?: boolean;
  isLastViewed?: boolean;
  isAnimated?: boolean;
  isTopTenancy?: boolean;
  isQuickSale?: boolean;
  marketPageView?: boolean;
  pricePeriod?: string;
  onPropertyCardHover?: () => void;
  onPropertyCardLeave?: () => void;
  isLocalExpert?: boolean;
  lazilyLoadFirstImage?: boolean;
}

export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const perWeekToPerMonth = (price: number): number =>
  Math.round((price * 52) / 12);

export const getPrice = (
  instructionType: string,
  priceTo: number,
  priceFrom: number,
  pricePeriod: string,
  pricePcm: string
) => {
  if (instructionType === 'sale') {
    if (priceTo !== priceFrom) {
      return `£${numberWithCommas(
        Math.round(priceFrom)
      )} to £${numberWithCommas(Math.round(priceTo))}`;
    }

    return `£${numberWithCommas(Math.round(priceFrom))}`;
  }

  const rentFrom =
    pricePeriod === 'PerWeek'
      ? Math.round(priceFrom)
      : perWeekToPerMonth(priceFrom);
  const rentTo =
    pricePeriod === 'PerWeek'
      ? Math.round(priceTo)
      : perWeekToPerMonth(priceTo);

  if (priceTo !== priceFrom) {
    return `£${numberWithCommas(rentFrom)} to £${numberWithCommas(rentTo)} ${
      pricePeriod === 'PerWeek' ? 'pw' : 'pcm'
    }`;
  }

  return pricePeriod === 'PerWeek'
    ? `£${numberWithCommas(rentFrom)} pw`
    : `£${numberWithCommas(Number(pricePcm) || rentFrom)} pcm`;

  // From develop - Do we need this now?
  // const pricePcmOrFrom = pricePcm ?? priceFrom;

  // return pricePeriod === 'PerWeek'
  //   ? `£${numberWithCommas(Math.round(priceFrom))} pw`
  //   : `£${numberWithCommas(Number(pricePcmOrFrom))} pcm`;
};

function LibPropertyListCard({
  property,
  isRecommendedProperty = false,
  cardButtons,
  isPortal = {
    isPortalCard: false,
    isSalesPrice: false,
    isMapView: false
  },
  heartIconOnClick,
  handleLoginClick,
  favorited = false,
  isPropertySelected = false,
  isNewHomePage = false,
  isMapCard = false,
  cancelSelection,
  isNew = false,
  isLastViewed = false,
  isTopTenancy = false,
  isQuickSale = false,
  isAnimated = false,
  marketPageView = false,
  pricePeriod,
  selectedPropertyIdCard = '',
  onPropertyCardHover,
  onPropertyCardLeave,
  isLocalExpert = false,
  lazilyLoadFirstImage
}: LibPropertyListCardProps) {
  const [isFavorited, setIsFavorited] = useState(favorited);
  const [listSavedProp, setListSavedProp] = useState([]);
  // ================================================================================
  // Property: Save Status Monitoring ==========================================
  const [isPropSaved, setIsPropSaved] = useState(false);
  const [isMortgageCalculatorVisible, setMortgageCalculatorVisibility] =
    useState(false);
  const [, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    hasNotification: false,
    notificationContent: '',
    notificationType: 'success'
  });
  const { user } = getUserFromStorage();
  const isLoggedIn = user?.isLoggedIn;
  const { hasMounted } = useClientOnly();
  // This router's functionality is only applicable on website. Will not work on portal.
  const router = useRouter();
  const feeMayApplyRef = React.useRef(null);
  const pathname = usePathname();
  const isRecommendedRentalsPage = !isReactApp()
    ? pathname?.includes(propertyListPagePathConstant.recommendedRentals) ||
      pathname?.includes(propertyListPagePathConstant.recommendedShortLets)
    : window.location.pathname?.includes(
        propertyListPagePathConstant.recommendedRentals
      ) ||
      window.location.pathname?.includes(
        propertyListPagePathConstant.recommendedShortLets
      );

  // UseEffect to handle internal saving mechanism for cards displayed on map =======

  useEffect(() => {
    // checking if use is logged in while card belongs to Area-map-card
    if (isLoggedIn && isMapCard) {
      // Fetching all Saved-Properties and evaluating that current property is saved or not
      getUserSavedProperties();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && isMapCard) {
      isPropertySaved();
    }
  }, [listSavedProp]);

  useEffect(() => {
    const { redirectUrl, hasLoggedIn } = getAuthActionFromSessionStorage();

    if (
      user?.isLoggedIn &&
      redirectUrl === router?.asPath &&
      hasLoggedIn &&
      isMapCard
    ) {
      const isAlreadySaved = isPropertySaved();

      if (!isAlreadySaved) {
        toggleSaveProperty(propertyReference || '', instructionType);
      }

      sessionStorage.removeItem('propertyAction');
    }
  }, [listSavedProp]);

  // isPropertyMarkedSaved or not
  const saveStatus = isMapCard ? isPropSaved : favorited;
  const [savePropertyStatus, setSavePropertyStatus] = useState(false);

  useEffect(() => {
    setSavePropertyStatus(saveStatus);
  }, [saveStatus]);

  const handlePropertySaveIconClick = (e) => {
    setSavePropertyStatus(!savePropertyStatus);
    heartIconOnClick(e, savePropertyStatus);
  };

  useEffect(() => {
    // Sync local state with props whenever it changes
    setIsFavorited(favorited);
  }, [favorited]);

  const handleFavoriteClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    heartIconOnClick(event, isFavorited);

    if (isPortal?.searchPropertyType !== 'SAVED_PROPERTY') {
      setIsFavorited(!isFavorited);
    }
  };

  const {
    type,
    streetName,
    postcodeShort,
    propertyReference,
    bedrooms,
    bathrooms,
    priceTo,
    priceFrom,
    propertyBlob,
    isSneakPeek,
    isReduced,
    isRecentSold,
    isRecentLet,
    isNewHome,
    buildToRent,
    hasVideo,
    hasVirtualTour,
    instructionType,
    isUnderOffer,
    availableForAuction,
    pricePcm
  } = property || {};
  const showVideoIcon =
    hasVirtualTour ||
    hasVideo ||
    (isPortal?.isPortalCard && Boolean(isPortal?.videoTour));

  const {
    floorArea,
    locationName,
    landLordAcceptZeroDeposit,
    priceAskingType
  } = propertyBlob || {};

  // CONDITIONS =================================================================
  const isSneakPeakOverlay = () => isSneakPeek && !isLoggedIn && hasMounted;

  // Locating properties of newhomes page which has collection of other newhomes properties
  const isNewHomeDevelopment =
    property?.inUkDevelopment === 1 &&
    instructionType === 'sale' &&
    (property?.class === 'development' || property?.class === 'category');

  const isBuildToRentDevelopment =
    buildToRent === 1 &&
    instructionType === 'letting' &&
    (property?.class === 'development' || property?.class === 'category');

  // Evaluating only rented properties (3)
  const isRentProperty =
    instructionType === 'short_letting' || instructionType === 'letting';

  // Identifying properties for sale with and without under-offer
  const isSalePropertyNormal =
    instructionType === 'sale' &&
    !isUnderOffer &&
    !isRecentSold &&
    !isNewHomeDevelopment;

  const isSalePropertyUnderOffer =
    instructionType === 'sale' &&
    isUnderOffer &&
    !isRecentSold &&
    !isNewHomeDevelopment;

  // Only show area field for parking space type property (1)(2)
  const isParkingSpace =
    type === 'garage' || type === 'parking space' || type === 'parking';

  const isNormalProperty =
    !isParkingSpace && !isNewHomeDevelopment && !isBuildToRentDevelopment;
  // Evaluating Auction Properties (6)
  const isUpForAuction = availableForAuction === 1;

  // Evaluating Properties with zero deposit (5)
  const islandLordAcceptingZeroDeposit = landLordAcceptZeroDeposit === 1;

  // If property is selected via map or otherwise, it should be highlighted
  const variableStyles = isPropertySelected
    ? {
        ...styles.highlightCard,
        ...(isLocalExpert && styles.localExpertHeight),
        ...(property?.isLocalLifeDetailPage && styles.localLifeCard)
      }
    : {
        ...(isPortal?.isPortalCard && isReactApp()
          ? styles.baseStateOpen
          : isLocalExpert
          ? {
              ...styles.baseStateOpenWeb,
              height:
                isSalePropertyUnderOffer || isRentProperty ? '22.2rem' : '22rem'
            }
          : styles.baseStateOpenWeb),
        ...(property?.isLocalLifeDetailPage && styles.localLifeCard)
      };
  // ============================================================================

  // CONSTANTS ==================================================================
  const tagsList: TagTypes = {
    isRecommended: isRecommendedProperty,
    isNewHome: isNewHome && instructionType === 'sale',
    isBuildToRent: buildToRent && instructionType === 'letting',
    isReduced,
    isSneakPeek,
    isRecentSold,
    isRecentLet,
    availableForAuction: isUpForAuction,
    landLordAcceptZeroDeposit: islandLordAcceptingZeroDeposit,
    isNew,
    isLastViewed,
    isTopTenancy,
    isQuickSale
  };

  const getPropertyUrl = (propertyType: string) => {
    const searchPagesPaths: { [key: string]: string } = {
      sale: 'properties-for-sale',
      letting: 'properties-to-rent',
      short_letting: 'short-let-properties'
    };

    return `${searchPagesPaths[propertyType]}`;
  };

  const getPropertyInstruction = (propertyPath: string) =>
    propertyPath.indexOf('short-let-properties') > -1
      ? 'short_letting'
      : 'letting';

  const address = locationName
    ? `${streetName}, ${locationName}, ${postcodeShort}`
    : `${streetName}, ${postcodeShort}`;

  let hrefLink = '';

  if (
    isRecommendedRentalsPage &&
    property.recommendedRentalsProps?.instructionId
  ) {
    // get current query params
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set(
      'instruction_id',
      property.recommendedRentalsProps.instructionId
    );
    hrefLink = `/${
      instructionType === 'letting'
        ? propertyListPagePathConstant.recommendedRentals
        : propertyListPagePathConstant.recommendedShortLets
    }/${postcodeShort?.toLowerCase()}/${propertyReference}?${queryParams.toString()}`;
  } else if (
    (isNewHomeDevelopment || isBuildToRentDevelopment) &&
    property.class === 'development'
  ) {
    hrefLink = `/${isBuildToRentDevelopment ? 'buildtorent' : 'newhomes'}/${
      isNewHomePage ? property.webName : property.developmentName
    }`;
  } else {
    hrefLink = `/${getPropertyUrl(
      instructionType
    )}/${postcodeShort?.toLowerCase()}/${propertyReference}`;
  }

  const propertyPhotos = property?.propertyBlob?.assetInfo?.assets?.photos;

  const picsCarousel =
    propertyPhotos?.length > 0
      ? property?.propertyBlob?.assetInfo?.assets?.photos.map(
          (photo: {
            index: string;
            propertyReference: string;
            filename: string;
            timestamp: string;
          }) => ({
            id: `${propertyReference}_${photo?.index}`,
            title: photo?.propertyReference,
            url: photoAssetUrlNew(
              photo?.propertyReference,
              photo?.filename,
              photo?.timestamp,
              '480',
              isPortal?.portalAssetBaseUrl
            )
          })
        )
      : [
          {
            id: `${propertyReference}_0`,
            title: `${propertyReference}_${
              isSneakPeek ? 'sneak_peek' : 'default'
            }`,
            url: isSneakPeek ? '/webImages/sneakPeekAni.svg' : ''
          }
        ];

  // Fees may apply Handle ========================================================
  const handleFeesMayApplyButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (isRentProperty || isPortal?.weeklyRent) {
      feeMayApplyRef?.current?.handleDrawerOpen();
    }
  };

  // ==============================================================================

  // Finding the current property from list of total saved properties
  const isPropertySaved = () => {
    const savedProperty = listSavedProp?.find(
      (item: {
        property: {
          property: {
            propertyReference: string;
          };
          instructionPrice: {
            instructionPriceType: string;
          };
        };
      }) =>
        item?.property?.property?.propertyReference === propertyReference &&
        item?.property?.instructionPrice?.instructionPriceType ===
          instructionType
    );

    if (savedProperty) {
      setIsPropSaved((prev) => !prev);
    }

    return Boolean(savedProperty);
  };

  // Fetching all saved properties and updating state of list of properties
  const getUserSavedProperties = async () => {
    setIsLoading(true);

    try {
      const { response } = await getUserFavoritePropertiesList();
      const savedPropertyData = response?.data;

      setIsLoading(false);

      setListSavedProp(savedPropertyData);
    } catch {
      setIsLoading(false);
    }
  };

  // Toggling of saveIcon
  const toggleSaveProperty = async (
    propReference: string,
    propInstructionType: string
  ) => {
    const isSaved = isPropertySaved();

    setIsLoading(true);

    const response = await togglePropertyFavorite(
      propInstructionType,
      propReference,
      isSaved
    );

    if (response.success) {
      setIsLoading(false);

      getUserSavedProperties();
    } else {
      setIsLoading(false);

      setNotification({
        hasNotification: true,
        notificationContent: 'Something went wrong. Please try again.',
        notificationType: 'error'
      });
    }
  };

  const saveIconStatus = () =>
    savePropertyStatus ? (
      <FavoriteIcon sx={styles.iconFilled} />
    ) : (
      <FavoriteUnfilledIcon sx={styles.icon} />
    );

  // ================================================================================
  /* eslint-disable no-nested-ternary */
  return (
    <>
      {/* Mortagage calculator */}
      {isSalePropertyNormal && (
        <LenderSelectionDrawer
          open={isMortgageCalculatorVisible}
          setOpen={setMortgageCalculatorVisibility}
          propertyPrice={priceFrom}
        />
      )}
      {/* flutter animation */}
      {!isAnimated ? null : isSneakPeakOverlay() ? null : (
        <>
          <Box className="hoverPoint" sx={styles.tiltLeft} />
          <Box className="hoverPoint" sx={styles.tiltRight} />
        </>
      )}

      {/* ------------------ Card Starts here ------------------ */}
      {isSneakPeek && !isLoggedIn ? (
        <Box
          sx={
            isPortal?.isPortalCard && isReactApp()
              ? {
                  ...styles.portalCardShadow,
                  ...styles.btnLink,
                  ...styles.overflowControl
                }
              : { textDecoration: 'none' }
          }
          onMouseEnter={onPropertyCardHover}
          onMouseLeave={onPropertyCardLeave}
        >
          <Card
            className="baseContent"
            sx={deepmerge(
              marketPageView
                ? { ...styles.marketPageViewCard }
                : isPortal?.isPortalCard && isReactApp()
                ? { ...styles.root }
                : marketPageView
                ? null
                : { ...styles.webRoot },
              isSneakPeakOverlay() ? styles.baseStateSneakPeak : variableStyles
            )}
          >
            {/* Is property sneak Peek: Show solid overlay  */}
            {isSneakPeakOverlay() ? (
              <Box sx={styles.overlayWrapper}>
                <Typography
                  variant="h5"
                  component="h2"
                  data-selected-card-heading-id={selectedPropertyIdCard}
                  sx={styles.sneakPeakTextMain}
                >
                  Sneak peek
                </Typography>
                <Typography
                  variant="body1"
                  data-selected-card-text-id={selectedPropertyIdCard}
                  sx={styles.sneakPeakTextSecondary}
                >
                  Sign in to access sneak peeks
                </Typography>
                <Button
                  variant="primary"
                  sx={styles.signInButton}
                  onClick={(e) => {
                    if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'old') {
                      const redirectUrl = {
                        redirect_url: router?.asPath
                          ? `${process.env.NEXT_PUBLIC_URL}${router?.asPath}`
                          : window?.location.href
                      };

                      localStorage.setItem(
                        'sneakpeek',
                        JSON.stringify(redirectUrl)
                      );
                    }

                    e.stopPropagation();
                    e.preventDefault();
                    handleLoginClick(e);
                  }}
                >
                  Sign in
                </Button>
              </Box>
            ) : null}

            {!isSneakPeek || (isLoggedIn && hasMounted) ? (
              property?.isLocalLifeDetailPage ? (
                <CardActionArea
                  sx={{ position: 'initial' }}
                  disableRipple
                  disableTouchRipple
                  LinkComponent="div"
                >
                  <CardContent>
                    <CloseIcon
                      sx={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        cancelSelection();
                      }}
                    />
                    <Typography sx={styles.localLifeCardTitle}>
                      {property?.name}
                    </Typography>
                    <Box sx={styles.saleInfoContainer}>
                      <Image
                        src="/webImages/saleIncrease.svg"
                        style={{ width: '1rem', height: '1rem' }}
                        width={0}
                        height={0}
                        alt="sales"
                      />
                      <Box sx={styles.saleText}>{property?.saleInfo}</Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              ) : (
                <CardActionArea
                  // In case when this card is coming explicitly from portal,
                  // use the isPortal.linkSite url to navigate.
                  // If isPortal key is unavailable, still check if host is react app
                  // and prepend VITE_URL to hrefLink to navigate to Foxtons Website.
                  href={
                    isPortal?.isPortalCard
                      ? isPortal?.linkSite
                      : isReactApp()
                      ? getEnvVar('URL') + hrefLink
                      : hrefLink
                  }
                  target={
                    isPortal?.isMapView && isReactApp() ? '_blank' : '_self'
                  }
                  sx={{ position: 'initial' }}
                  disableRipple
                  disableTouchRipple
                  LinkComponent="div"
                >
                  <Box
                    sx={
                      isReactApp() && !isPortal?.isMapView
                        ? styles.imageContainerPortal
                        : styles.imageContainer
                    }
                  >
                    {/* Video Icons */}
                    {showVideoIcon ? (
                      <Box sx={styles.videoTourIconBox}>
                        <VideoTourSolid />
                      </Box>
                    ) : null}

                    {/* Tag Names */}
                    <PropertyPill
                      totalNoTags={2}
                      tagsList={
                        isPortal?.isPortalCard ? isPortal?.tags : tagsList
                      }
                      isPortal={isPortal?.isPortalCard}
                    />

                    {/* Carousel Images */}
                    {isPortal?.carouselImages && (
                      <EmblaImageCarousel
                        images={isPortal?.carouselImages}
                        lazilyLoadFirstImage={lazilyLoadFirstImage}
                      />
                    )}
                  </Box>

                  {/* Card Content */}
                  {!isPortal?.isPortalCard && (
                    <CardContent sx={styles.cardContainer}>
                      {/* Address */}
                      <Box
                        sx={
                          marketPageView
                            ? {
                                ...styles.addressAndOtherDetails,
                                ...styles.addressContainerForMarketPage
                              }
                            : styles.addressAndOtherDetails
                        }
                      >
                        {address && (
                          <Typography
                            variant="body1"
                            sx={{
                              ...styles.addressText,
                              ...(isNewHomePage && styles.newHomeAddressText)
                            }}
                            className="addressText"
                          >
                            {address}
                          </Typography>
                        )}

                        {!marketPageView && (
                          <Grid container columnSpacing={1}>
                            {/* Bedrooms */}
                            {isNormalProperty && (
                              <Grid item xs={4} sx={styles.iconTextWrapper}>
                                {bedrooms || parseInt(bedrooms, 10) === 0 ? (
                                  <>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(bedrooms, 10) === 0
                                        ? 'Studio'
                                        : `${bedrooms} Beds`}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(bedrooms, 10) === 0
                                        ? 'Studio'
                                        : ''}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            )}
                            {/* Bathrooms */}
                            {isNormalProperty && Boolean(bathrooms) && (
                              <Grid item xs={4} sx={styles.iconTextWrapper}>
                                <SvgBathtub className="svgIcon" />
                                <Typography
                                  variant="body2"
                                  sx={styles.iconText}
                                  className="iconText"
                                >
                                  {bathrooms && parseInt(bathrooms, 10)
                                    ? `${bathrooms} Baths`
                                    : ''}
                                </Typography>
                              </Grid>
                            )}
                            {/* FloorArea */}
                            {!isNewHomeDevelopment && Boolean(floorArea) && (
                              <Grid item xs={4} sx={styles.iconTextWrapper}>
                                <SvgHouseArea className="svgIcon" />
                                <Typography
                                  variant="body2"
                                  sx={styles.iconText}
                                  className="iconText"
                                >
                                  {floorArea
                                    ? `${numberWithCommas(floorArea)} sq.ft`
                                    : ''}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Box>
                      {!marketPageView && (
                        <Box sx={styles.priceBlockWeb}>
                          <Box>
                            {/* Price Text */}
                            <Typography variant="body1" sx={styles.weeklyRent}>
                              {priceAskingType && `${priceAskingType} `}
                              {getPrice(
                                instructionType,
                                priceTo,
                                priceFrom,
                                pricePeriod,
                                pricePcm
                              )}
                            </Typography>
                            {isSalePropertyNormal && (
                              <Tooltips
                                title="Enter a custom deposit and mortgage rate to see all properties display your monthly repayments"
                                placement="top"
                                arrow
                                baseStyles={{ maxWidth: '17rem' }}
                              >
                                {/* If propertyType is sale and is not under offer */}
                                <Button
                                  sx={styles.drawerBtn}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setMortgageCalculatorVisibility(true);
                                  }}
                                >
                                  {/* Calculate my mortgage payments */}
                                  {hasMounted && checkMortgageData(priceTo)}
                                </Button>
                              </Tooltips>
                            )}

                            {/* If propertyType is sale and is Under Offer */}
                            {isSalePropertyUnderOffer && (
                              <Typography variant="subtitle2" component="span">
                                Under offer
                              </Typography>
                            )}
                            {/* If propertyType is not sale */}
                            {isRentProperty && (
                              <Button
                                sx={styles.drawerBtn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleFeesMayApplyButton(e);
                                }}
                              >
                                Fees may apply
                              </Button>
                            )}
                          </Box>
                          {/* Save Icon */}
                          {/* saveIconClick: internal saving function,  heartIconOnClick: toggling of saving from outside func */}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handlePropertySaveIconClick(e);
                            }}
                            aria-label="favorite"
                            sx={styles.iconBtn}
                          >
                            {saveIconStatus()}
                          </IconButton>
                        </Box>
                      )}
                      {marketPageView && (
                        <Box>
                          <Typography variant="body2">
                            {property.title}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  )}

                  {/* Card Content - PORTAL */}
                  {isPortal?.isPortalCard && (
                    <CardContent sx={styles.cardContainer}>
                      <Box sx={styles.detailsContainer}>
                        {address && (
                          <Box sx={styles.addressAndOtherDetails}>
                            <Typography
                              variant="body1"
                              sx={styles.addressText}
                              className="addressText"
                            >
                              {isPortal?.address}
                            </Typography>
                            {!isNewHomePage && (
                              <Box sx={styles.bedBathAreaDetails}>
                                {isPortal?.bedrooms ||
                                parseInt(isPortal?.bedrooms, 10) === 0 ? (
                                  <Box sx={styles.iconTextWrapper}>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(isPortal?.bedrooms, 10) === 0
                                        ? 'Studio'
                                        : `${isPortal?.bedrooms} Beds`}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={styles.iconTextWrapper}>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(isPortal?.bedrooms, 10) === 0
                                        ? 'Studio'
                                        : '- Beds'}
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={styles.iconTextWrapper}>
                                  <SvgBathtub className="svgIcon" />
                                  <Typography
                                    variant="body2"
                                    sx={styles.iconText}
                                    className="iconText"
                                  >
                                    {isPortal?.bathrooms &&
                                    parseInt(isPortal?.bathrooms, 10)
                                      ? `${isPortal?.bathrooms} Baths`
                                      : '- Baths'}
                                  </Typography>
                                </Box>
                                <Box sx={styles.iconTextWrapper}>
                                  <SvgHouseArea className="svgIcon" />
                                  <Typography
                                    variant="body2"
                                    sx={styles.iconText}
                                    className="iconText"
                                  >
                                    {isPortal?.area
                                      ? `${isPortal?.area} sq.ft`
                                      : '- sq.ft'}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                        {isNewHomePage ? (
                          <Grid item sx={styles.priceRange}>
                            {isPortal?.weeklyRent}
                          </Grid>
                        ) : (
                          <Box sx={styles.priceBlock}>
                            <Box>
                              {isPortal?.weeklyRent ? (
                                <Typography
                                  variant="body1"
                                  sx={styles.weeklyRent}
                                >
                                  {convertPrice(isPortal?.weeklyRent)}
                                  {router?.asPath?.includes('house-prices')
                                    ? ''
                                    : isPortal?.isPricePerMonth
                                    ? ' pcm'
                                    : !isPortal?.isSalesPrice
                                    ? ' pw'
                                    : ''}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={styles.weeklyRent}
                                >
                                  {convertPrice(isPortal?.weeklyRent)}
                                </Typography>
                              )}
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleFeesMayApplyButton(e);
                                }}
                                sx={styles.feesMayApplyCta}
                              >
                                Fees may apply
                              </Button>
                            </Box>
                            <IconButton
                              onClick={handleFavoriteClick}
                              aria-label="favorite"
                              sx={styles.iconBtn}
                            >
                              {isFavorited ? (
                                <FavoriteIcon sx={styles.iconFilled} />
                              ) : (
                                <FavoriteUnfilledIcon sx={styles.icon} />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  )}

                  {/* Card buttons */}
                  {cardButtons ? (
                    <Box sx={styles.btnBox}>
                      {cardButtons.primaryBtn ? (
                        <Button
                          variant="primary-dark"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            cardButtons.primaryBtn.primaryBtnClick(e);
                          }}
                        >
                          {cardButtons.primaryBtn.label}
                        </Button>
                      ) : null}
                      {cardButtons.secondaryBtn ? (
                        <Button
                          variant="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            cardButtons.secondaryBtn.secondaryBtnClick(e);
                          }}
                        >
                          {cardButtons.secondaryBtn.label}
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                </CardActionArea>
              )
            ) : null}
          </Card>
        </Box>
      ) : (
        <Link
          href={
            isPortal?.isPortalCard
              ? isPortal?.linkSite
              : isReactApp()
              ? getEnvVar('URL') + hrefLink
              : hrefLink
          }
          target={isPortal?.isMapView && isReactApp() ? '_blank' : '_self'}
          sx={
            isPortal?.isPortalCard && isReactApp()
              ? {
                  ...styles.btnLink,
                  ...styles.overflowControl,
                  ...styles.portalCardShadow
                }
              : { textDecoration: 'none' }
          }
        >
          <Card
            className="baseContent"
            sx={deepmerge(
              marketPageView
                ? { ...styles.marketPageViewCard }
                : isPortal?.isPortalCard && isReactApp()
                ? { ...styles.root }
                : marketPageView
                ? null
                : { ...styles.webRoot },
              isSneakPeakOverlay() ? styles.baseStateSneakPeak : variableStyles
            )}
          >
            {/* Is property sneak Peek: Show solid overlay  */}
            {isSneakPeakOverlay() ? (
              <Box sx={styles.overlayWrapper}>
                <Typography
                  variant="h5"
                  component="p"
                  data-selected-card-heading-id={selectedPropertyIdCard}
                  sx={styles.sneakPeakTextMain}
                >
                  Sneak peek
                </Typography>
                <Typography
                  variant="body1"
                  data-selected-card-text-id={selectedPropertyIdCard}
                  sx={styles.sneakPeakTextSecondary}
                >
                  Sign in to access sneak peeks
                </Typography>
                <Button
                  variant="primary"
                  sx={styles.signInButton}
                  onClick={(e) => {
                    if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'old') {
                      const redirectUrl = {
                        redirect_url: router?.asPath
                          ? `${process.env.NEXT_PUBLIC_URL}${router?.asPath}`
                          : window?.location.href
                      };

                      localStorage.setItem(
                        'sneakpeek',
                        JSON.stringify(redirectUrl)
                      );
                    }

                    e.stopPropagation();
                    e.preventDefault();
                    handleLoginClick(e);
                  }}
                >
                  Sign in
                </Button>
              </Box>
            ) : null}

            {!isSneakPeek || (isLoggedIn && hasMounted) ? (
              property?.isLocalLifeDetailPage ? (
                <CardActionArea
                  sx={{ position: 'initial' }}
                  disableRipple
                  disableTouchRipple
                  LinkComponent="div"
                >
                  <CardContent>
                    <CloseIcon
                      sx={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        cancelSelection();
                      }}
                    />
                    <Typography sx={styles.localLifeCardTitle}>
                      {property?.name}
                    </Typography>
                    <Box sx={styles.saleInfoContainer}>
                      <Image
                        src="/webImages/saleIncrease.svg"
                        style={{ width: '1rem', height: '1rem' }}
                        width={0}
                        height={0}
                        alt="sales"
                      />
                      <Box sx={styles.saleText}>{property?.saleInfo}</Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              ) : (
                <CardActionArea
                  // In case when this card is coming explicitly from portal,
                  // use the isPortal.linkSite url to navigate.
                  // If isPortal key is unavailable, still check if host is react app
                  // and prepend VITE_URL to hrefLink to navigate to Foxtons Website.
                  href={
                    isPortal?.isPortalCard
                      ? isPortal?.linkSite
                      : isReactApp()
                      ? getEnvVar('URL') + hrefLink
                      : hrefLink
                  }
                  target={
                    isPortal?.isMapView && isReactApp() ? '_blank' : '_self'
                  }
                  sx={{ position: 'initial' }}
                  disableRipple
                  disableTouchRipple
                  LinkComponent="div"
                >
                  <Box
                    sx={
                      isReactApp() && !isPortal?.isMapView
                        ? styles.imageContainerPortal
                        : styles.imageContainer
                    }
                  >
                    {/* Video Icons */}
                    {showVideoIcon ? (
                      <Box sx={styles.videoTourIconBox}>
                        <VideoTourSolid />
                      </Box>
                    ) : null}

                    {/* Tag Names */}
                    <PropertyPill
                      totalNoTags={2}
                      tagsList={
                        isPortal?.isPortalCard ? isPortal?.tags : tagsList
                      }
                      isPortal={isPortal?.isPortalCard}
                    />

                    {/* Carousel Images */}
                    {!isPortal?.isPortalCard && (
                      <EmblaImageCarousel
                        images={picsCarousel}
                        lazilyLoadFirstImage={lazilyLoadFirstImage}
                      />
                    )}
                    {isPortal?.carouselImages && (
                      <EmblaImageCarousel
                        images={isPortal?.carouselImages}
                        lazilyLoadFirstImage={lazilyLoadFirstImage}
                      />
                    )}
                  </Box>

                  {/* Card Content */}
                  {!isPortal?.isPortalCard && (
                    <CardContent sx={styles.cardContainer}>
                      {/* Address */}
                      <Box
                        sx={
                          marketPageView
                            ? {
                                ...styles.addressAndOtherDetails,
                                ...styles.addressContainerForMarketPage
                              }
                            : styles.addressAndOtherDetails
                        }
                      >
                        {address && (
                          <Typography
                            variant="body1"
                            sx={{
                              ...styles.addressText,
                              ...(isNewHomePage && styles.newHomeAddressText)
                            }}
                            className="addressText"
                          >
                            {address}
                          </Typography>
                        )}

                        {!marketPageView && (
                          <Grid container columnSpacing={1}>
                            {/* Bedrooms */}
                            {isNormalProperty && (
                              <Grid item xs={4} sx={styles.iconTextWrapper}>
                                {bedrooms || parseInt(bedrooms, 10) === 0 ? (
                                  <>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(bedrooms, 10) === 0
                                        ? 'Studio'
                                        : `${bedrooms} Beds`}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(bedrooms, 10) === 0
                                        ? 'Studio'
                                        : ''}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            )}
                            {/* Bathrooms */}
                            {isNormalProperty && Boolean(bathrooms) && (
                              <Grid item xs={4} sx={styles.iconTextWrapper}>
                                <SvgBathtub className="svgIcon" />
                                <Typography
                                  variant="body2"
                                  sx={styles.iconText}
                                  className="iconText"
                                >
                                  {bathrooms && parseInt(bathrooms, 10)
                                    ? `${bathrooms} Baths`
                                    : ''}
                                </Typography>
                              </Grid>
                            )}
                            {/* FloorArea */}
                            {!isNewHomeDevelopment &&
                              !isBuildToRentDevelopment &&
                              Boolean(floorArea) && (
                                <Grid item xs={4} sx={styles.iconTextWrapper}>
                                  <SvgHouseArea className="svgIcon" />
                                  <Typography
                                    variant="body2"
                                    sx={styles.iconText}
                                    className="iconText"
                                  >
                                    {floorArea
                                      ? `${numberWithCommas(floorArea)} sq.ft`
                                      : ''}
                                  </Typography>
                                </Grid>
                              )}
                          </Grid>
                        )}
                      </Box>
                      {!marketPageView && (
                        <Box sx={styles.priceBlockWeb}>
                          <Box>
                            {/* Price Text */}
                            <Typography
                              component="p"
                              variant="h4"
                              sx={styles.weeklyRent}
                            >
                              {priceAskingType && `${priceAskingType} `}
                              {getPrice(
                                instructionType,
                                priceTo,
                                priceFrom,
                                pricePeriod,
                                pricePcm
                              )}
                            </Typography>
                            {isSalePropertyNormal && (
                              <Tooltips
                                title="Enter a custom deposit and mortgage rate to see all properties display your monthly repayments"
                                placement="top"
                                arrow
                                baseStyles={{ maxWidth: '17rem' }}
                              >
                                {/* If propertyType is sale and is not under offer */}
                                <Button
                                  aria-label="mortgage-calculator"
                                  sx={styles.drawerBtn}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setMortgageCalculatorVisibility(true);
                                  }}
                                >
                                  {/* Calculate my mortgage payments */}
                                  {hasMounted && checkMortgageData(priceTo)}
                                </Button>
                              </Tooltips>
                            )}

                            {/* If propertyType is sale and is Under Offer */}
                            {isSalePropertyUnderOffer && (
                              <Typography variant="subtitle2" component="span">
                                Under offer
                              </Typography>
                            )}
                            {/* If propertyType is not sale */}
                            {isRentProperty && (
                              <Button
                                sx={styles.drawerBtn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleFeesMayApplyButton(e);
                                }}
                              >
                                Fees may apply
                              </Button>
                            )}
                          </Box>
                          {/* Save Icon */}
                          {/* saveIconClick: internal saving function,  heartIconOnClick: toggling of saving from outside func */}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handlePropertySaveIconClick(e);
                            }}
                            aria-label="favorite"
                            sx={styles.iconBtn}
                          >
                            {saveIconStatus()}
                          </IconButton>
                        </Box>
                      )}
                      {marketPageView && (
                        <Box>
                          <Typography variant="body2">
                            {property.title}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  )}

                  {/* Card Content - PORTAL */}
                  {isPortal?.isPortalCard && (
                    <CardContent sx={styles.cardContainer}>
                      <Box sx={styles.detailsContainer}>
                        {address && (
                          <Box sx={styles.addressAndOtherDetails}>
                            <Typography
                              variant="body1"
                              sx={
                                {
                                  ...styles.addressText,
                                  ...styles.wrapText
                                } as SxProps
                              }
                              className="addressText"
                            >
                              {isPortal?.address}
                            </Typography>
                            {!isNewHomePage && (
                              <Box sx={styles.bedBathAreaDetails}>
                                {isPortal?.bedrooms ||
                                parseInt(isPortal?.bedrooms, 10) === 0 ? (
                                  <Box sx={styles.iconTextWrapper}>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(isPortal?.bedrooms, 10) === 0
                                        ? 'Studio'
                                        : `${isPortal?.bedrooms} Beds`}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={styles.iconTextWrapper}>
                                    <SvgBed className="svgIcon" />
                                    <Typography
                                      variant="body2"
                                      sx={styles.iconText}
                                      className="iconText"
                                    >
                                      {parseInt(isPortal?.bedrooms, 10) === 0
                                        ? 'Studio'
                                        : '- Beds'}
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={styles.iconTextWrapper}>
                                  <SvgBathtub className="svgIcon" />
                                  <Typography
                                    variant="body2"
                                    sx={styles.iconText}
                                    className="iconText"
                                  >
                                    {isPortal?.bathrooms &&
                                    parseInt(isPortal?.bathrooms, 10)
                                      ? `${isPortal?.bathrooms} Baths`
                                      : '- Baths'}
                                  </Typography>
                                </Box>
                                <Box sx={styles.iconTextWrapper}>
                                  <SvgHouseArea className="svgIcon" />
                                  <Typography
                                    variant="body2"
                                    sx={styles.iconText}
                                    className="iconText"
                                  >
                                    {isPortal?.area
                                      ? `${isPortal?.area} sq.ft`
                                      : '- sq.ft'}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                        {isNewHomePage ? (
                          <Grid item sx={styles.priceRange}>
                            {isPortal?.weeklyRent}
                          </Grid>
                        ) : (
                          <Box sx={styles.priceBlock}>
                            <Box>
                              {isPortal?.weeklyRent ? (
                                <Typography
                                  variant="body1"
                                  sx={styles.weeklyRent}
                                >
                                  {convertPrice(isPortal?.weeklyRent)}
                                  {router?.asPath?.includes('house-prices')
                                    ? ''
                                    : isPortal?.isPricePerMonth
                                    ? ' pcm'
                                    : !isPortal?.isSalesPrice
                                    ? ' pw'
                                    : ''}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={styles.weeklyRent}
                                >
                                  {convertPrice(isPortal?.weeklyRent)}
                                </Typography>
                              )}

                              {isSalePropertyNormal && (
                                <Tooltips
                                  title="Enter a custom deposit and mortgage rate to see all properties display your monthly repayments"
                                  placement="top"
                                  arrow
                                  baseStyles={{ maxWidth: '17rem' }}
                                >
                                  {/* If propertyType is sale and is not under offer */}
                                  <Button
                                    sx={styles.drawerBtn}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setMortgageCalculatorVisibility(true);
                                    }}
                                  >
                                    {/* Calculate my mortgage payments */}
                                    {hasMounted && checkMortgageData(priceTo)}
                                  </Button>
                                </Tooltips>
                              )}

                              {/* If propertyType is sale and is Under Offer */}
                              {isSalePropertyUnderOffer && (
                                <Typography variant="body1">
                                  Under offer
                                </Typography>
                              )}

                              {isRentProperty && (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleFeesMayApplyButton(e);
                                  }}
                                  sx={styles.feesMayApplyCta}
                                >
                                  Fees may apply
                                </Button>
                              )}
                            </Box>
                            <IconButton
                              onClick={handleFavoriteClick}
                              aria-label="favorite"
                              sx={styles.iconBtn}
                            >
                              {isFavorited ? (
                                <FavoriteIcon sx={styles.iconFilled} />
                              ) : (
                                <FavoriteUnfilledIcon sx={styles.icon} />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  )}

                  {/* Card buttons */}
                  {cardButtons ? (
                    <Box sx={styles.btnBox}>
                      {cardButtons.primaryBtn ? (
                        <Button
                          variant="primary-dark"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            cardButtons.primaryBtn.primaryBtnClick(e);
                          }}
                        >
                          {cardButtons.primaryBtn.label}
                        </Button>
                      ) : null}
                      {cardButtons.secondaryBtn ? (
                        <Button
                          variant="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            cardButtons.secondaryBtn.secondaryBtnClick(e);
                          }}
                        >
                          {cardButtons.secondaryBtn.label}
                        </Button>
                      ) : null}
                      {isPortal && cardButtons.label ? cardButtons.label : null}
                    </Box>
                  ) : null}
                </CardActionArea>
              )
            ) : null}
          </Card>
        </Link>
      )}

      {/* ------------------ Card Ends here ------------------ */}

      {isRentProperty || isPortal?.weeklyRent ? (
        <FeesMayApplySection
          propertyType={
            isPortal?.isPortalCard
              ? getPropertyInstruction(isPortal?.linkSite)
              : instructionType
          }
          propertyName={isPortal?.address || address}
          propertyPrice={isPortal?.weeklyRent || priceTo}
          ref={feeMayApplyRef}
        />
      ) : null}

      <Alert
        open={notification.hasNotification}
        severity={notification.notificationType}
        title={notification.notificationType}
        description={notification.notificationContent}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() =>
          setNotification((prevState) => ({
            ...prevState,
            hasNotification: false
          }))
        }
        autoHideEnable
        autoHideAfter={3000}
      />
    </>
  );
}

export const PropertyListCard = React.memo(LibPropertyListCard);

export default PropertyListCard;

/*
PROPS: 
01) isRecommended: prop to display chip of Recommended in cases where it needs to be seen. It wouldnt be shown on property search page card or property detail page. Will only be shown in sections like Recommended Prperties.
02) isPortal: To differentiate between properties coming via web or portal
03) basePath: to evaluate url
04) isMapCard: to track where these cards are displayed via map pins. Boolean false for cards displayed in grid list and Boolean true for cards displayed on map. Currently it is also linked with saved functionality being operated internally. 

CASES:
01) Parking property can be from sproperty of sale and property of rent: In case of property of sale, calculating mortgages will be shown while in case of rente properties, fees may apply be seen.
02) In case of parking properties, only area would be shown, no bedroom and/or bathrooms 
03) isRentProperty: evaluating that property is short let or long let property
04) isRecentSold: if property is recently sold, no need to show either text below (e.g. fees may.. or calc mortage..).
05) islandLordAcceptingZeroDeposit: would show up on properties-for-rent
06) isUpForAuction: would show up on properties-for-sale
07) property specs (i.e. no-of-bedroom, bathroom) would not be shown for isNewHomeDevelopment properties; also note point#02

*/
